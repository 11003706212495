import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import DownloadAppButton from './DownloadAppButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { doordash, hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
        >
          <Header />
          <CFView textCenter column center>
            <CFView>
              <OrderPickupButton />
            </CFView>
            <CFView>
              <DownloadAppButton />
            </CFView>
            <a href="https://www.doordash.com/store/hanayuki-sushi-port-moody-688752/en-US">
              <CFView hover>
                <CFImage src={doordash} w="85%" maxWidth="320px" alt="About" />
              </CFView>
            </a>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) center/ cover no-repeat`}
          zIndex={90}
          relative
          column
          center
        >
          <CFView w="100%" absolute top={0} left={0}>
            <Header />
          </CFView>
          <CFView row alignEnd justifyCenter h="600px" mb="5%" ml="2%">
            <CFView>
              <OrderPickupButton />
            </CFView>
            <CFView ml="10px">
              <DownloadAppButton />
            </CFView>
            <a href="https://www.doordash.com/store/hanayuki-sushi-port-moody-688752/en-US">
              <CFView hover ml="10px" mb="5px">
                <CFImage src={doordash} maxWidth="250px" alt="About" />
              </CFView>
            </a>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
